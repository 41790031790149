import React from 'react';

const LineIndicator = (props) => {
    const {
        maxValue,
        value,
        direction,
    } = props;

    // const dirToFlex = dir => dir === 'horizontal' ? 'row' : 'column';

    return (
        <div className='guess-indicator' style={{flexDirection: direction}}>
            {
                Array(value).fill({}).map((o, i) =>
                    <div key={`lock${i}`} className='locked'>&nbsp;</div>
                )
            }
            {
                Array(maxValue - value).fill({}).map((o, i) =>
                    <div key={`remain${i}`} className='remain'>&nbsp;</div>
                )
            }
        </div>
    );
};

export default LineIndicator;