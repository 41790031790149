import React, {
    useEffect,
    useState,
    useRef,
    useReducer,
    useMemo,
    useCallback
} from 'react';
import { useLocation } from 'react-router-dom';
import Matter from 'matter-js';

import Header from '../components/Header';
import Keyboard from '../components/Keyboard';
import Modal from '../components/Modal';
import Footer from '../components/Footer';
import { WordList, Dictionary } from '../components/WordList';
import useEventListener from '../components/useEventListener';
import LineIndicator from '../components/LineIndicator';

// Necessary to make sure Matter.Plugin.register() runs for it.
import MatterRenderBackground from '../components/MatterRenderBackground';

import * as buff from 'buffer';
const Buffer = buff.Buffer;

const isSafari = /^((?!chrome|android).)*safari/i.test(
    navigator?.userAgent
  );
const isNotFirefox = navigator.userAgent.indexOf('Firefox') < 0;

var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

const useQuery = () => {
    const { search } = useLocation();

    // console.info(new URLSearchParams('?q=test'));

    return useMemo(() => new URLSearchParams(search), [search]);
}

const Worbble = (props) => {
    const {
        mode
    } = props;

    const query = useQuery();
    let customWordCode = '';
    let customWord = '';
    if (query.has('w')) {
        customWordCode = query.get('w');
        customWord = Buffer.from(customWordCode, 'base64').toString('ascii');
        console.info('custom word loaded', customWord);
    }

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const canvasRef = useRef(undefined);
    const modalRef = useRef(undefined);
    // console.info(customWord, customWord.toString('ascii'));

    var Engine = Matter.Engine,
        Render = Matter.Render,
        World = Matter.World,
        Bodies = Matter.Bodies,
        Events = Matter.Events;

    const DailyWord = () => {
        // Get daily word from word list

        if (customWord) {
            if (customWord.length !== 5) {
                return '';
            }
            return customWord;
        } else {
            var start = new Date('02/21/2022');
            var today = new Date();
            var diff = today.getTime() - start.getTime();
            var currentDay = Math.floor(diff / (1000 * 3600 * 24));

            return WordList[currentDay % WordList.length];
        }
    };
    // console.info('word is ', DailyWord());

    const WORD_MAX_LENGTH = 5;
    const MAX_GUESSES = 6;
    const correctWord = DailyWord();//'words';
    const [pastGuesses, setPastGuesses] = useState([]);
    const [currentGuess, setCurrentGuess] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const degToRad = degree => degree * 0.0174533;


    const defaultSettings = {
        displayMode: 'light', // 'dark'
        useWordList: true,
        accelerometer: false,
    };

    const [settings, setSettings] = useState(defaultSettings);
    const LoadSettings = () => {
        const storedSettings = JSON.parse(localStorage.getItem('settings'));
        if (storedSettings) setSettings(storedSettings);
        // console.info(storedSettings, settings);
        // console.info('load settings', storedSettings, settings);
    }

    const SaveSettings = (settingsToSave) => {
        // console.info('save settings', settingsToSave, settings);
        if (settingsToSave) {
            localStorage.setItem('settings', JSON.stringify(settingsToSave));
        } else {
            localStorage.setItem('settings', JSON.stringify(settings));
        }
    }
    useEffect(LoadSettings, []);
    useEffect(SaveSettings, [settings]);

    // Bodies.rectangle(100, 50, 50, 50, {
    //     // isStatic: true,
    //     label: 'c',
    //     angle: degToRad(10),
    //     render: {
    //         fillStyle: 'green',
    //         // strokeStyle: 'black',
    //         // lineWidth: 1,
    //     },
    // });

    var ground = Bodies.rectangle(400, 380, 810, 100, {
        label: '',
        isStatic: true,
        render: {
            fillStyle: 'white',
            strokeStyle: 'black',
            lineWidth: 1,
        }
    });

    // https://brm.io/matter-js/docs/classes/Body.html#properties
    var letter = Bodies.rectangle(100, 50, 50, 50, {
        label: 'c',
        angle: degToRad(10),
        render: {
            fillStyle: 'green',
        },
    });

    // localstorage values
    const [showTutorial] = useState(() => {
        const value = JSON.parse(localStorage.getItem('tutorial'));
        if (value === null) {
            return true;
        } else {
            return value;
        }
    });

    // misc state
    const [gameEndReason, setGameStatus] = useState(''); // won, lost
    const [engine] = useState(Engine.create());
    const [render, setRender] = useState(undefined);
    const [bodies, setBodies] = useState([ground]);
    const [guessBodies, setGuessBodies] = useState([]);
    const [guessMap, setGuessMap] = useState({});

    useEffect(() => {
        if (customWord && customWord.length !== 5) {
            modalRef.current.show({
                title: 'invalid custom worbble',
                body: 'the secret word provided is not 5 letters in length',
                actions: [
                    {
                        label: 'aw shucks', onClick: () => {
                            // modalRef.current.hide();
                            window.location = window.location.origin + window.location.pathname;
                        }
                    }
                ]
            });
        }
    }, [modalRef])


    const addGuessLetter = letter => {
        const leftMargin = 85;
        const angleRange = 20;
        var newLetter = Bodies.rectangle(leftMargin + currentGuess.length * 55, 50, 50, 50, {
            label: letter.toUpperCase(),
            angle: degToRad(-(angleRange / 2) + Math.random() * (angleRange * 2)),
            render: {
                fillStyle: 'white',
                strokeStyle: 'black',
                lineWidth: 1,
            },
        });

        setGuessBodies([
            ...guessBodies,
            newLetter,
        ])

        World.add(engine.world, newLetter);
    };
    const removeLastGuess = () => {
        const removed = guessBodies.splice(guessBodies.length - 1, 1);
        setGuessBodies(guessBodies);
        // console.info(removed, guessBodies);
        World.remove(engine.world, removed);
        forceUpdate();
    };

    useEffect(() => {
        if (showTutorial) {
            modalRef.current.show({
                title: 'Tutorbbial',
                body: <div>
                    <p>You seem like a scrub so here's how it is:</p>
                    <p><strong>Problem:</strong> There is a {WORD_MAX_LENGTH}-letter word. You don't know it.</p>
                    <p><strong>Solution:</strong> Type out your {WORD_MAX_LENGTH}-letter guess and press [ENTER]. The letters will change color depending on how close they are:</p>
                    <p>
                        <span style={{ color: 'green', fontWeight: 'bold' }}>Green</span> - Correct letter <em>and</em> correct place in the word. <br />
                        <span style={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold' }}>Yellow</span> - Correct letter but <em>incorrect</em> place in the word. <br />
                        <span style={{ color: 'grey', fontWeight: 'bold' }}>Grey</span> - The letter does not exist in the word.
                    </p>
                    <p>You have {MAX_GUESSES} to figure it out!</p>
                </div>,
                actions: [
                    {
                        label: 'cool, let me worbble', closes: true, onClick: () => {
                            localStorage.setItem('tutorial', false);
                        }
                    },
                ],
            });
        }
    }, []);

    useEffect(() => {
        
        Matter.use('matter-render-background');
        
        var newRender = Render.create({
            element: canvasRef.current,
            engine: engine,
            options: {
                width: 375,
                height: 375,
                wireframes: false,
                background: 'white',
            }
        });

        setRender(newRender);

        World.add(engine.world, bodies);

        Matter.Runner.run(engine);
        Render.run(newRender);

        console.info(Matter.Plugin);
        // Events.on(newRender, 'afterRender', (e) => {
        //     console.info(e);
        //     e.source.context.fillStyle='red';
        //     e.source.context.fillRect(0,0,400,400);
        // });
        // console.info(newRender);
    }, [
        canvasRef
    ]);

    const onInput = (input) => {
        if (gameEndReason) {
            return;
        }

        if (currentGuess.length < WORD_MAX_LENGTH) {
            setCurrentGuess(currentGuess + input);
            addGuessLetter(input);
        }
    };

    const onBack = () => {
        setCurrentGuess(currentGuess.substring(0, currentGuess.length - 1));
        removeLastGuess();
    }

    const onSubmit = () => {
        if (gameEndReason) {
            return;
        }

        if (pastGuesses.length < MAX_GUESSES) {
            if (currentGuess.length === WORD_MAX_LENGTH) {
                setErrorMessage('');

                // Only run word list check if it's not a custom wordle and we have that setting enabled.
                if (settings.useWordList &&
                    !Dictionary.includes(currentGuess.toLowerCase())) {
                    setErrorMessage(`'${currentGuess.toUpperCase()}' NOT IN WORD LIST`);
                    if (!customWord) {
                        return;
                    }
                }

                setPastGuesses([
                    ...pastGuesses,
                    currentGuess
                ]);
                setCurrentGuess('');

                // Go through the squares and recolor the guesses.
                var correct = 0;
                var letterMap = {};
                Object.values(correctWord.toUpperCase()).forEach(c => {
                    if (c in letterMap) letterMap[c]++;
                    else letterMap[c] = 1;
                });

                // Wrong
                guessBodies.forEach((body, i) => {
                    body.render.fillStyle = '#ddd';
                    body.render.strokeStyle = '#ccc';
                    if (!guessMap[body.label])
                        guessMap[body.label] = 'grey';
                });

                // Correct
                guessBodies.forEach((body, i) => {
                    body.render.lineWidth = 0;
                    // console.info(letterMap[body.label]);

                    if (letterMap[body.label] > 0 &&
                        body.label === correctWord[i].toUpperCase()) {
                        body.render.fillStyle = 'green';
                        body.render.strokeStyle = 'white';
                        letterMap[body.label]--;
                        correct++;
                        guessMap[body.label] = 'green';
                    }
                });

                // Close
                guessBodies.forEach((body, i) => {
                    if (letterMap[body.label] > 0 &&
                        correctWord.toUpperCase().includes(body.label) &&
                        body.render.fillStyle !== 'green') {
                        body.render.fillStyle = 'yellow';
                        body.render.strokeStyle = 'black';
                        letterMap[body.label]--;
                        if (guessMap[body.label] !== 'green')
                            guessMap[body.label] = 'yellow';
                    }
                });

                // Object.keys(letterMap).forEach(key => {
                //     guessMap[key] = letterMap[]
                // });

                if (correct === WORD_MAX_LENGTH) {
                    console.info(canvasRef.current);

                    setGameStatus('won');
                    setErrorMessage('');
                }
                setBodies([...bodies, ...guessBodies]);
                setGuessBodies([]);

            } else {
                setErrorMessage('GUESS MUST BE 5 LETTERS');
            }
        } else {
            // No more guesses
        }
    }

    const isLetter = c => c.length === 1 && c.match(/[a-z]/i);

    const onKeydown = useCallback((e) => {
        if (e.key === 'Enter') {
            onSubmit(e.key);
        } else if (e.key === 'Backspace') {
            onBack();
        } else if (isLetter(e.key)) {
            onInput(e.key);
        }
    });
    useEventListener('keydown', onKeydown);

    // useEffect(() => {
    //     try {
    //     } catch { }
    // }, []);

    const [rotations, setRotations] = useState({ x: null, y: null, z: null });
    const onMotion = useCallback((e) => {
        // console.info('motion', e);
        setRotations(e.accelerationIncludingGravity);
        if (settings.accelerometer) {
            const slide = e.accelerationIncludingGravity.x;
            engine.gravity.x = Math.max(-5, Math.min(slide, 5)) / 5;
        }
        // engine.gravity.y = e.acceleration.x;
    });
    useEventListener('devicemotion', onMotion);

    useEffect(() => {
        if (pastGuesses.length === MAX_GUESSES && gameEndReason !== 'won') {
            setGameStatus('lost');
            setErrorMessage('');
        }
    }, [pastGuesses]);

    // const WonModalBody = (props) => {
    //     const {
    //         image,
    //     } = props;
    //     const [copyButtonText, setCopyButtonText] = useState('copy to clipboard please');

    //     return (
    //         <div>
    //             You're the worbble best! You did it! We all sing your praises! The chosen one has appeared!
    //             <img src={image} />
    //         </div>
    //     )
    // }

    useEffect(() => {
        switch (gameEndReason) {
            case 'won':
                setTimeout(() => {
                    console.info(render.canvas);
                    const ctx = render.context;
                    const mX = render.canvas.width / 2;
                    const mY = render.canvas.height / 2;
                    
                    ctx.save();
                    ctx.textAlign='center';
                    ctx.font = 'bold 12pt Arial';
                    ctx.fillStyle='black';
                    // ctx.strokeText('PlayWorbble.com', mX, render.canvas.height - 25);
                    ctx.fillText('PlayWorbble.com', mX, render.canvas.height - 25);
                    
                    ctx.shadowColor = 'rgba(1,1,1,.2)';
                    ctx.shadowBlur = 7;
                    ctx.lineWidth = 3;

                    // Logo
                    ctx.fillStyle = 'white';
                    ctx.font = 'bold 24pt Roboto Serif';
                    ctx.strokeText('Worbble!', mX, 100);
                    ctx.fillText('Worbble!', mX, 100);

                    // ctx.fillStyle = 'black';
                    ctx.font = 'bold 72pt Roboto Serif';

                    ctx.strokeText(`${pastGuesses.length}/${MAX_GUESSES}`, mX, mY);
                    ctx.fillText(`${pastGuesses.length}/${MAX_GUESSES}`, mX, mY);
                    
                    ctx.restore();
    // if(render) {
    //     render.context.fillStyle='red';
    //     render.context.fillRect(20, 20, 20, 20);
    // }
                    const finalCanvasUrl = render.canvas.toDataURL();

                    let showCopyOption = false;
                    if (!isMobile) {
                        if (isNotFirefox) {
                            showCopyOption = true;
                        }
                    } else {
                        if (isSafari) {
                            showCopyOption = true;
                        }
                    }

                    render.canvas.toBlob(blob => {
                        modalRef.current.show({
                            title: 'CONGRATULATIONS',
                            body: <div>
                                You're the worbble best! You did it! We all sing your praises! The chosen one has appeared!
                                <img src={finalCanvasUrl} />
                            </div>,
                            actions: [
                                {
                                    label: 'omg thanks', closes: true,
                                },
                                {
                                    label: 'copy to clipboard', 
                                    hide: !showCopyOption,
                                    onClick: () => {
                                        // if (isSafari) {
                                            try {
                                                navigator.clipboard.write([
                                                    new window.ClipboardItem({'image/png': blob}),
                                                ]);
                                                // const clip = new ClipboardEvent('copy');
                                                // console.info(clip);
                                                // clip.clipboardData.setData('image/png', blob);
                                                // clip.preventDefault();
                                                // alert('copied!');
                                                modalRef.current.hide();
                                            } catch (e) {
                                                alert(e);
                                            }
                                        // } else {

                                        // }
                                    }
                                }
                            ],
                        });
                    })
                }, 500);
                break;

            case 'lost':
                setTimeout(() => {
                    modalRef.current.show({
                        title: 'ouch',
                        body: `aw dang, the word was actually '${correctWord.toUpperCase()}'. better luck next day buckaroo`,
                        actions: [
                            {
                                label: 'the shame is immense', closes: true,
                            },
                        ],
                    });
                }, 500)
                break;
            default:
                // nothin'
                break;
        }
    }, [gameEndReason]);

    const CustomShareModalBody = () => {
        const [shareWord, setShareWord] = useState('');
        const { origin, pathname } = window.location;
        const SharePath = `${origin}${pathname}?w=${Buffer.from(shareWord, 'ascii').toString('base64')}`;
        const [copyButtonText, setCopyButtonText] = useState('Copy');
        return <div>
            <p>Want to share a custom worbble with your friends? Type in the word below and copy the custom URL!</p>
            <p><input
                type='text'
                placeholder='word'
                value={shareWord}
                // style={{width: '100%'}}
                onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue.toString().length <= 5) {
                        console.info(shareWord, e.target.value);
                        setShareWord(e.target.value);
                    }
                }} /></p>
            <p>
                <input
                    type='text'
                    value={SharePath}
                    disabled={true} />
                <br />
                <button onClick={() => {
                    navigator.clipboard.writeText(SharePath);
                    setCopyButtonText('Copied!');
                }}
                    disabled={shareWord.length != 5}>
                    {copyButtonText}
                </button>
            </p>
        </div>
    };

    const ShowCustomShareModal = () => {
        modalRef.current.show({
            title: 'Custom Worbble',
            body: <CustomShareModalBody />,
            actions: [
                {
                    label: 'ok done', closes: true,
                },
            ]
        })
    }

    const SettingsModalBody = () => {
        const [editSettings, setEditSettings] = useState(settings);
        return (
            <div className='settings-modal-body'>
                <label>
                    <div className='spacer' />
                    Display Mode: &nbsp;
                    <select>
                        <option value='light'>Light</option>
                    </select>
                </label>

                <label>
                    <input
                        type='checkbox'
                        checked={editSettings.useWordList}
                        onChange={e => {
                            const mod = { ...editSettings };
                            mod.useWordList = e.target.checked;
                            setEditSettings(mod);
                            setSettings(mod);
                        }}
                    />
                    Only Allow Dictionary Guesses
                </label>

                <label
                    className={`${editSettings.accelerometer && rotations.x? 'warning' : ''}`}>
                    <input
                        type='checkbox'
                        checked={editSettings.accelerometer && rotations.x}
                        onChange={e => {
                            try {
                                DeviceMotionEvent.requestPermission();
                            } catch {}

                            const mod = { ...editSettings };
                            mod.accelerometer = e.target.checked;
                            setEditSettings(mod);
                            setSettings(mod);
                        }}
                    />
                    Experimental Accelerometer Mode
                </label>
                {
                    !rotations.x &&
                    <span style={{ fontSize: '12px', lineHeight: '12px' }}>Your device isn't granting us permission to access rotation data. You may be asked to grant permission when the box is checked.</span>
                }

            </div>
        );
    };

    const ShowSettingsModal = () => {
        modalRef.current.show({
            title: 'Settings!',
            body: <SettingsModalBody />,
            actions: [
                {
                    label: 'i\'m happy with my decisions', closes: true,
                }
            ]
        })
    };

    return <>
        <Modal
            ref={modalRef} />
        <Header
            title={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='animate-header'>Worbble</div>
                    <div style={{
                        borderBottom: '.5pt dashed grey',
                    }} />
                    {
                        customWord &&
                        <span className='title-custom'>CUSTOM</span>
                    }
                </div>
            }
            leftButton={
                <div>
                    <button onClick={ShowCustomShareModal}>Share Custom Word</button>
                </div>
            }
            rightButton={
                <div>
                    <button onClick={ShowSettingsModal}>Settings!</button>
                </div>
            }
        />
        {
            customWord &&
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '12px',
            }}>
                <button onClick={() => {
                    window.location = window.location.origin + window.location.pathname;
                }}>Switch to Daily Worbble</button>
            </div>
        }

        <div className='grid'>
            <div className='middle-section'>
                {/* <div className='guess-indicator'>
                    {
                        Object.values(currentGuess).map((o, i) =>
                            <div key={`lock${i}`} className='locked'>&middot;</div>
                        )
                    }
                    {
                        Array(MAX_GUESSES - currentGuess.length - 1).fill({}).map((o, i) =>
                            <div key={`remain${i}`} className='remain'>&middot;</div>
                        )
                    }
                </div> */}

                <div className='grid-container'>
                    <div ref={canvasRef} className='physics' />

                    <div
                        className='physics'>
                        <LetterOverlay
                            objects={[...bodies, ...guessBodies]}
                            canvas={canvasRef}
                        />
                    </div>


                    <div className='error-message'>
                        {errorMessage}
                    </div>

                    <div style={{ position: 'relative', left: '165px'}}>
                        <LineIndicator
                            direction='column-reverse'
                            value={pastGuesses.length}
                            maxValue={MAX_GUESSES}
                        />
                    </div>
                </div>
                <div style={{position: 'relative', top: '-385px'}}>
                    <LineIndicator
                        direction='row'
                        value={currentGuess.length}
                        maxValue={WORD_MAX_LENGTH}
                    />
                </div>

            </div>
            <Keyboard
                onInput={onInput}
                onBack={onBack}
                onSubmit={onSubmit}
                enterEnabled={currentGuess.length === 5 && !gameEndReason}
                keyStateMap={guessMap}
            />
        </div>
        <Footer />
    </>

};

const LetterOverlay = (props) => {
    const {
        objects,
        canvas
    } = props;
    
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const fps = frames => 1000 / frames;
    const radToDeg = rad => rad * 57.2958;

    useEffect(() => {
        setInterval(forceUpdate, fps(60));
    }, []);

    if (!objects || !canvas || !canvas.current) return <></>;

    return <>
        {
            objects.map((object, i) => {
                const { vertices, position, angle, label, render } = object;
                return <span
                    key={`letter-${label}-${i}`}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        position: 'absolute',
                        color: object.render.strokeStyle,
                        fontSize: '24px',
                        fontWeight: 'bold',
                        left: `${position.x - 25}px`,
                        top: `${position.y - 25}px`,
                        transform: ` rotate(${radToDeg(angle)}deg)`,
                    }}>
                    {label.toUpperCase() || ''}
                </span>
            })
        }
    </>
};


export default Worbble;