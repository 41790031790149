import Matter from 'matter-js'

var MatterRenderBackground = {
    name: 'matter-render-background',

    version: '0.1.0',

    for: 'matter-js@^0.10.0',

    options: {
        something: true
    },

    install: function(base) {
        base.before('Render.bodies', function(options) {
            MatterRenderBackground.Engine.beforeRenderBodies(options);
        });
    },

    Engine: {
        beforeRenderBodies: function(options) {
            options.context.fillStyle = options.options.background;
            options.context.fillRect(0, 0, options.canvas.width, options.canvas.height);
        },
    }
};

Matter.Plugin.register(MatterRenderBackground);