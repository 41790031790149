import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            Copyright &copy; 2022 <a href='http://www.jordancarroll.com'>Jordan Carroll</a> - All Rights Reserved <br />
            Enjoying Worbble? <a href='https://ko-fi.com/ploogle'>Buy me a coffee!</a>
        </div>
    )
};

export default Footer;