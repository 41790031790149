import React, { useState } from "react";

// import '../styles/wordle.scss';

const Keyboard = (props) => {
    const {
        onInput,
        onBack,
        onSubmit,
        enterEnabled,
        keyStateMap,
    } = props;

    const keys = [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['enter', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back']
    ]

    return <div className='keyboard-container'>
        <div className='keyboard'>
            {
                keys.map((row, i) =>
                    <div
                        key={`row${i}`}
                        className='row'>
                        {
                            row.map(key => {
                                if (key.length === 1) {
                                    return <div
                                        key={key}
                                        className={`key ${keyStateMap[key.toUpperCase()]}`}
                                        onClick={() => onInput(key)}>
                                        {key}
                                    </div>
                                } else {
                                    switch (key) {
                                        case 'back':
                                            return <div
                                                key={key}
                                                className='key'
                                                onClick={() => onBack()}>
                                                &lt;&lt;
                                            </div>
                                        case 'enter':
                                            return <div
                                                key={key}
                                                className={`key large ${enterEnabled ? '' : 'disabled'}`}
                                                onClick={() => {
                                                    if (enterEnabled) {
                                                        onSubmit()
                                                    }
                                                }}>
                                                ENTER
                                            </div>
                                    }
                                }
                            }
                            )
                        }
                    </div>)
            }
        </div>
    </div>
};

export default Keyboard;