import React, {
    useEffect,
    useImperativeHandle,
    useState,
    useRef,
    useContext,
} from 'react';

const Modal = React.forwardRef((props, ref) => {

    const [open, setOpen] = useState(props.visible);
    const [visible, setVisible] = useState(false);
    const [options, setDisplayOptions] = useState(props);
    const wrapper = useRef(undefined);

    let {
        title,
        body,
        actions,
    } = options;

    // useEffect(() => {
    //     let {
    //         title,
    //         body,
    //         actions,
    //     } = options;
    // }, [options]);


    const onAnimationEnd = (e) => {
        // console.info(e);
        if (e.animationName === 'modal-hide') {
            setVisible(false);
        }
    };

    // useEffect(() => {
    //     console.info(wrapper);


    //     if (wrapper.current) {
    //         wrapper.current.onanimationend = onAnimationEnd;
    //     }

    //     return () => {
    //         console.info('cleanup animationend');
    //         wrapper.current.onanimationend = null;
    //     }
    // }, [ wrapper ]);

    const showModal = (props) => {
        setOpen(true);
        setVisible(true);
        setDisplayOptions(props);
    }

    const hideModal = () => {
        setOpen(false);
        // setDisplayOptions(props);
    };

    useImperativeHandle(ref, () => ({
        show: showModal,
        hide: hideModal,
    }));

    if (!visible) return <></>;

    return <div
        ref={wrapper}
        className={`modal-wrapper ${!open ? 'modal-hide' : ''}`}
        onAnimationEnd={onAnimationEnd}
    >
        <div className='modal-container'>
            <div className='modal-title'>
                <div className='animate-header'>{title}</div>
                <div style={{ borderBottom: '1pt dashed grey', width: '100%', paddingBottom: '8px' }} />
            </div>
            <div className='modal-body'>
                {body}
            </div>
            <div className='modal-options-container'>
                {
                    actions &&
                    actions.map((action, i) => {
                        if (action.hide ||
                            (action.show !== undefined && !action.show)) return <></>;

                        return (
                            <div
                                key={`action${i}`}
                                className='modal-option'
                                onClick={() => {
                                    if (action.onClick) {
                                        action.onClick();
                                    }

                                    if (action.closes) {
                                        hideModal();
                                    }
                                }}>
                                {action.label}
                            </div>
                        );
                    })
                }
            </div>
        </div>
        <div className='modal-shadow' />
    </div>
});

export default Modal;