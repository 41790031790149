import React, { useState } from "react";

// import '../styles/wordle.scss';

const Header = (props) => {
    const {
        title,
        leftButton,
        rightButton,
    } = props;
    return <div className='header'>
        <div className='button-section'>
            { leftButton || 'Fun!' }
        </div>
        <div className='logo'>
            {title}
        </div>
        <div className='button-section'>
            { rightButton || 'Letters!' }
        </div>
    </div>
};

export default Header;